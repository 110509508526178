import 'idempotent-babel-polyfill';
require('es6-promise').polyfill();
window.axios = require('axios');

import Helpers from '../Helpers';

/**
 * Front Recommend Install Settings
 */
class RecommendInstall {
    /**
     * Constructor Method
     */
    constructor() {
        this.helpers = new Helpers();
        this.sRecommendInstallModule = 'recommend-install';
        this.sRedirectAppModule = 'recommend-redirect-to-app';
        this.sAppUrl = this.helpers.getAppUrl(this.sRecommendInstallModule);
        this.sCssUrl = `${this.sAppUrl}/css/front/recommend-install.css`;
        $('head').append(`<link rel="stylesheet" href="${this.sCssUrl}" type="text/css" />`);
        this.sMallId = EC_FRONT_JS_CONFIG_MANAGE.sMallId;
        this.sShopNo = EC_SDE_SHOP_NUM;
        this.sNation = EC_GLOBAL_INFO.getCountryCode().toLocaleLowerCase();
        this.aAddServiceInfo = [];
        this.sRecommendCookieName = 'DO_NOT_DISPLAY_RECOMMEND_POPUP';
        this.sRecommendCookieCount = 'RECOMMEND_POPUP_COUNT';
        this.iPageVisitMaxCount = 3;
        this.iPageVisitCount = 0;
        if (this.helpers.isPreviewMode() === true) {
            $('body').css('pointer-events', 'none');
        }
        this.getSettingsData();
        this.initializePreview();
    }

    /**
     * API Call to the Server
     * @param oRequest
     * @return {Promise<AxiosResponse | AxiosInterceptorManager<AxiosResponse> | any>}
     */
    async apiRequest(oRequest) {
        try {
            return await axios({
                method: oRequest.method,
                url: oRequest.url,
                data: oRequest.data,
            });
        } catch (error) {
            return error.response;
        }
    }

    /**
     * show the popup based on preview mode settings
     * @returns {boolean}
     */
    initializePreview() {
        if (this.helpers.isPreviewMode() === false) {
            return false;
        }

        this.helpers.addRedirectCssScript(this.sAppUrl);

        let oInstallAppUrl = this.helpers.getUrlAsObject(this.sAppUrl);
        let sAdminUrl = oInstallAppUrl.root_domain !== 'local' ? `${oInstallAppUrl.subdomain}.${EC_ROOT_DOMAIN}` : this.sAppUrl;
        let oAdminMessage = { isScriptLoaded: true, module: this.sRecommendInstallModule };

        // send script status to admin app
        window.parent.postMessage(oAdminMessage, sAdminUrl);

        // get the recommendSettings thru window.postMessage()
        window.addEventListener('message', ({ origin, data: { settings, module } }) => {
            // origin checker - don't execute the code when the origin not matched
            if (origin !== sAdminUrl) {
                return false;
            }

            if (module === this.sRecommendInstallModule) {
                this.handleRecommendInstallPreview(settings);
            } else if (module === this.sRedirectAppModule) {
                this.handleRedirectAppPreview(settings);
            }
        });
    }

    handleRecommendInstallPreview(oRecommendSetting) {
        const aRecommendSettings = this.helpers.formatMarketSettingsUrlParams(oRecommendSetting);

        this.initialize(aRecommendSettings);
        // unbind and set the cursor to default
        $('#mCafe24AppInstall').find('a, button, img').css('cursor', 'default');
        $('#mCafe24AppInstall').find('a, button, img').unbind();
    }

    handleRedirectAppPreview(oRedirectSettings) {
        this.oSettings = this.helpers.formatMarketSettingsUrlParams(oRedirectSettings);
        if ($('#mCafe24DirectToApp').length > 0) {
            $('#mCafe24DirectToApp').remove();
        }

        // display redirect popup
        this.helpers.showRedirectPopup(oRedirectSettings.settings);
        $('#mCafe24DirectToApp').children().css('display', 'block');
        $('#mCafe24DirectToApp:is(.topTheme1, .topTheme2, .newImageTopTheme1, .newImageTopTheme2, .newImageTopTheme4) .layerBanner').css('display', 'flex');
        // set the cursor to default
        $('#mCafe24DirectToApp').find('a, button, img').css('cursor', 'default');
    }

    /**
     * Get the settings from the server
     * @return {Promise<boolean>}
     */
    async getSettingsData() {
        if (this.helpers.isPreviewMode() === true) {
            return false;
        }
        let sOsType = this.helpers.isIos() === true ? 'ios' : 'android';
        let oRequest = {
            method: 'GET',
            url: `${this.sAppUrl}/api/front/${this.sMallId}/recommend-install?shop_no=${this.sShopNo}&nation=${this.sNation}&os_type=${sOsType}`,
        };
        let oResponse = await this.apiRequest(oRequest);
        this.aAddServiceInfo = oResponse.data.data.serviceInformation;
        this.initialize(oResponse.data.data.recommendInstall);
        if (window.oRecommendRedirectToApp !== undefined) {
            await window.oRecommendRedirectToApp.init();
        }
    }

    /**
     * Setup the data after retrieval from the database
     * @param aRecommendSettings
     */
    initialize(aRecommendSettings) {
        let bShopIsEnabled = aRecommendSettings['plusapp_manager_enabled'];
        let bPlusAppServiceAvailable = aRecommendSettings['service_available'];
        if (this.helpers.isPreviewMode() === true || (bShopIsEnabled === true && bPlusAppServiceAvailable === true)) {
            if (this.helpers.isPreviewMode() === false && (this.helpers.isUnsupportedAgent() === true || this.helpers.getCookie(this.sRecommendCookieName) === 'true')) {
                return;
            }

            if (aRecommendSettings['use_install_popup'] === true) {
                this.checkPopupTiming(aRecommendSettings['popup_timing']);
                if (this.canOpenRecommendSettings() === true) {
                    // iPopupTimeout is set to 0 if we want to display popup immediately
                    // Set PopupTimeout to 1000 if we want to try to autoredirect
                    let iPopupTimeout = 0;
                    if (aRecommendSettings.is_auto_redirect === true) {
                        this.helpers.openAppOnly('app-download', this.sMallId, location.href, this.aAddServiceInfo);
                        iPopupTimeout = 1000;
                    }

                    if ($('#mCafe24AppInstall').length > 0) {
                        $('#mCafe24AppInstall').remove();
                    }
                    this.openRecommendInstallSettings(aRecommendSettings);

                    // Display Popup after iPopupTimeout,  meaning Automatic Redirection has failed
                    setTimeout(() => {
                        $('#mCafe24AppInstall').children().css('display', 'block');
                        $('#mCafe24AppInstall.topTheme1 .layerBanner, #mCafe24AppInstall.topTheme2 .layerBanner').css('display', 'flex');
                    }, iPopupTimeout);
                }
            }
        }
    }

    addCafe24RecommendInstallId() {
        $('body').prepend('<div id="mCafe24AppInstall"></div>');
    }

    /**
     * Open Recommend Settings depending on the Popup Type
     * @param aRecommendSettings
     */
    openRecommendInstallSettings(aRecommendSettings) {
        /**
         * Prevent duplicate dom
         */
        if (aRecommendSettings['popup_type'] === 'image') {
            this.openImagePopup(aRecommendSettings);
        } else if (aRecommendSettings['popup_type'] === 'button') {
            this.openButtonPopup(aRecommendSettings);
        } else if (aRecommendSettings['popup_type'] === 'text') {
            this.openTextPopup(aRecommendSettings);
        }
        $('#mCafe24AppInstall').children().css('display', 'none');
        return;
    }

    /**
     * Check if popup should be displayed
     * @return {*}
     */
    canOpenRecommendSettings() {
        if (this.helpers.isPreviewMode() === true) {
            return true;
        }

        if (this.iPageVisitCount !== this.iPageVisitMaxCount) {
            return false;
        }

        if (this.helpers.isAndroid() === true) {
            return this.helpers.checkOsAvailability('android_production_information', this.aAddServiceInfo);
        }

        if (this.helpers.isIos() === true) {
            return this.helpers.checkOsAvailability('ios_production_information', this.aAddServiceInfo);
        }

        return false;
    }

    /**
     * Check the value of popup timing
     * @param sPopupTiming
     */
    checkPopupTiming(sPopupTiming) {
        this.iPageVisitCount = parseInt(this.helpers.getCookie(this.sRecommendCookieCount), 10) || 0;
        if (sPopupTiming === 'count' && this.iPageVisitCount < this.iPageVisitMaxCount) {
            return this.incrementPageVisitCount();
        }
        this.iPageVisitCount = this.iPageVisitMaxCount;
    }

    /**
     * Increment the page visit count
     */
    incrementPageVisitCount() {
        this.iPageVisitCount += 1;
        this.helpers.setTodayCookie(this.sRecommendCookieCount, this.iPageVisitCount);
    }

    /**
     * Open the Play Store or App Store
     * @return {*|void}
     */
    async openAppOrStore() {
        if (this.helpers.isAndroid() === true) {
            await this.helpers.logAppstoreRedirects('Android', 'install_st', this.sShopNo, this.sMallId, this.sAppUrl, 'recommend_install');
            let aAndroidInformation = this.aAddServiceInfo.android_production_information;
            this.helpers.openAndroidApp(aAndroidInformation['app_id'], aAndroidInformation['google_linkid'], location.href, true);
        }

        if (this.helpers.isIos() === true) {
            await this.helpers.logAppstoreRedirects('iOS', 'install_st', this.sShopNo, this.sMallId, this.sAppUrl, 'recommend_install');
            let aIosInformation = this.aAddServiceInfo.ios_production_information;
            return this.helpers.openIosApp(aIosInformation['app_id'], aIosInformation['apple_linkid'], location.href, true);
        }
    }

    /**
     * Bind the event listener to the buttons
     */
    eventListener() {
        $('#closeRecommendInstall').click(() => {
            $('#mCafe24AppInstall').children().css('display', 'none');
            this.helpers.setTodayCookie(this.sRecommendCookieName, true);
        });
        $('.btnRecommendInstall').click(() => {
            $('#mCafe24AppInstall').children().css('display', 'none');
            this.openAppOrStore();
        });
    }

    /**
     * Popup Text HTML
     * @param aPopupSettings
     */
    openTextPopup(aPopupSettings) {
        let sMessageStyle = this.helpers.createRgbaFromHex(aPopupSettings['install_recommend_bg_color'], aPopupSettings['install_recommend_transparency']);
        let sConfirmBtnStyle = this.helpers.createRgbaFromHex(aPopupSettings['alert_confirm_button_bg_color'], aPopupSettings['alert_confirm_button_transparency']);
        let sCancelBtnStyle = this.helpers.createRgbaFromHex(aPopupSettings['alert_cancel_button_bg_color'], aPopupSettings['alert_cancel_button_transparency']);
        aPopupSettings['install_recommend_text'] = aPopupSettings['install_recommend_text'].replaceAll('\n', '<br>');
        let oTextHtml = `
            <div id="mCafe24AppInstall" class="newAlert" style="display:block;">
                <div class="dimmed"></div>
                <div class="layerBanner" style="background:${sMessageStyle}">
                    <p class="message" style="color:${aPopupSettings['install_recommend_text_color']}; font-size: ${aPopupSettings['install_recommend_font_size'] + 'px'};">${aPopupSettings['install_recommend_text']}</p>
                    <div class="btnBox">
                        <a href="#none" class="close" id="closeRecommendInstall" style="color: ${aPopupSettings['alert_cancel_button_font_color']}; background: ${sCancelBtnStyle}; ">
                            ${aPopupSettings['alert_cancel_button']}
                        </a>

                        <a href="#none" class="btnInstall btnRecommendInstall" style="color: ${aPopupSettings['alert_confirm_button_font_color']}; background: ${sConfirmBtnStyle};">
                            ${aPopupSettings['alert_confirm_button']}
                        </a>
                    </div>
                </div>
            </div>
        `;

        $('body').prepend(oTextHtml);
        this.eventListener();
    }

    /**
     * Return the new image design based on location and type
     * @param aPopupSettings
     * @returns {*}
     */
    getNewImageDesign(aPopupSettings) {
        let sImgIntroBGStyle = this.helpers.createRgbaFromHex(aPopupSettings['popup_image_intro_bg_color'], aPopupSettings['popup_image_intro_transparency']),
            sInstallBtnBGStyle = this.helpers.createRgbaFromHex(aPopupSettings['popup_image_install_bg_color'], aPopupSettings['popup_image_install_transparency']);
        let aAppLaunchIcon = this.helpers.isAndroid() === true ? aPopupSettings['app_launch_icon']['android'] : aPopupSettings['app_launch_icon']['ios'];
        let aImageTypes = {
            top: {
                A: `<div id="mCafe24AppInstall" class="topTheme1">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}; display: flex" >
                            <div class="logo"><img src="${aAppLaunchIcon}" width="40" height="40" alt=""></div>
                            <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}"><strong>${aPopupSettings['popup_image_intro_text']}</strong></p>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" ><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기"></a>
                        </div>
                    </div>`,
                B: `<div id="mCafe24AppInstall" class="topTheme2">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}; display: flex">
                            <div class="logo"><img src="${aAppLaunchIcon}" width="24" height="24" alt=""></div>
                            <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}"><strong>${aPopupSettings['popup_image_intro_text']}</strong></p>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" ><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기"></a>
                        </div>
                    </div>`,
            },

            center: {
                A: `<div id="mCafe24AppInstall" class="middleTheme3">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall"  style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
                B: `<div id="mCafe24AppInstall" class="middleTheme1">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
                C: `<div id="mCafe24AppInstall" class="middleTheme2">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="50" height="50" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" style="color:${aPopupSettings['popup_image_continue_text_color']}">${aPopupSettings['popup_image_continue_text']}<img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기"></a>
                        </div>
                    </div>`,
            },

            bottom: {
                A: `<div id="mCafe24AppInstall" class="bottomTheme1">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall"  style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
                B: `<div id="mCafe24AppInstall" class="bottomTheme2">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="50" height="50" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall"  style="color:${aPopupSettings['popup_image_continue_text_color']}">${aPopupSettings['popup_image_continue_text']}<img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기"></a>
                        </div>
                    </div>`,
                C: `<div id="mCafe24AppInstall" class="bottomTheme3">
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['popup_image_install_text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image_install_text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
            },
        };

        return aImageTypes[aPopupSettings['popup_location']][aPopupSettings['popup_image_default_type']];
    }

    /**
     * Return the common/default html design
     * @param aPopupSettings
     * @returns {string}
     */
    getDefaultImageDesign(aPopupSettings) {
        let iViewPortScale = window.screen.width / Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let oBody = $('body').width();
        let widthImage = 306;
        let widthButton = 15;
        let oPopupPositionList = {
            top: '5%',
            center: '35%',
            bottom: '70%',
        };
        let oPopupPosition = aPopupSettings['popup_image_type'] === 'register' ? oPopupPositionList[aPopupSettings['popup_location']] : oPopupPositionList.center;

        let sPopupImageUrl = aPopupSettings['popup_image_url'];

        if (aPopupSettings['popup_image_type'] === 'default') {
            sPopupImageUrl = `//img.echosting.cafe24.com/skin/mobile_${aPopupSettings['language_code']}/app/img_app_install.gif`;
        }

        if (iViewPortScale < 1) {
            if (window.screen.width >= 300 || window.innerWidth >= 300) {
                widthImage = oBody - oBody * iViewPortScale;
                widthButton = widthImage - widthImage * 0.95;
            }
            if (window.screen.width >= 700 || window.innerWidth >= 700) {
                widthImage = oBody - oBody * iViewPortScale;
                widthButton = widthImage - widthImage * 0.95;
            }
        }

        return `<div id="mCafe24AppInstall">
                    <div class="dimmed" style="display:block;"></div>
                    <div class="layerImage">
                        <div class="boxImage" style="display: block; width:80%; top: ${oPopupPosition} ">
                            <div class="close">
                                <a href="#none" id="closeRecommendInstall">
                                    <img src="//img.echosting.cafe24.com/skin/mobile/layout/ico_close_white.png" style="width:${widthButton}px; height:${widthButton}px;" alt="close">
                                </a>
                            </div>
                            <div class="btnInstall">
                                <a href="#none" class="btnRecommendInstall" >
                                    <img src="${sPopupImageUrl}" style="width:100%;" alt="앱 설치하기">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>`;
    }
    /**
     * Popup Image HTML
     * @param aPopupSettings
     */
    openImagePopup(aPopupSettings) {
        let oImageHtml = {};

        if (aPopupSettings['popup_image_type'] === 'default' && aPopupSettings['popup_image_default_type'] !== 'default') {
            oImageHtml = this.getNewImageDesign(aPopupSettings);
        } else if (aPopupSettings['popup_image_type'] === 'register' || (aPopupSettings['popup_image_type'] === 'default' && aPopupSettings['popup_image_default_type'] === 'default')) {
            oImageHtml = this.getDefaultImageDesign(aPopupSettings);
        }

        $('body').prepend(oImageHtml);
        this.eventListener();
    }

    /**
     * Popup Button HTML
     * @param aPopupSettings
     */
    openButtonPopup(aPopupSettings) {
        let sInstallButtonStyle = this.helpers.createRgbaFromHex(aPopupSettings['install_button_color'], aPopupSettings['install_button_transparency']);
        let sContinueButtonStyle = this.helpers.createRgbaFromHex(aPopupSettings['continue_button_color'], aPopupSettings['continue_button_transparency']);
        let oButtonHtml = `
                <div id="mCafe24AppInstall">
                    <div class="dimmed"></div>
                    <div class="layerButton" style="display: block; ${aPopupSettings['popup_location'] === 'center' ? 'top:50%' : 'top:90%'}">
                        <a href="#none" class="btnInstall btnRecommendInstall" style="color:${aPopupSettings['install_button_text_color']}; background:${sInstallButtonStyle};font-size:${aPopupSettings['install_button_font_size'] + 'px'};">
                            ${aPopupSettings['install_button_title']}
                        </a>
                        <a href="#none" class="btnWeb close" id="closeRecommendInstall" style="color:${aPopupSettings['continue_button_text_color']}; background:${sContinueButtonStyle};font-size:${aPopupSettings['continue_button_font_size'] + 'px'};">
                            ${aPopupSettings['continue_button_title']}
                        </a>
                    </div>
                </div>
            `;

        $('body').prepend(oButtonHtml);
        this.eventListener();
    }
}

new RecommendInstall();
